import { ApolloLink, HttpLink, Operation, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';

import { userSession } from '../../../helpers';
import { GRAPHQL_API_ENDPOINT } from '../env-vars';

import { authLink } from './authLink';
import { errorLink } from './errorLink';

const ON_SERVER = typeof window === 'undefined';

const createWsRoleLink = (role: string) =>
  new GraphQLWsLink(
    createClient({
      url: `wss://${GRAPHQL_API_ENDPOINT.get()}`,
      connectionParams: () => {
        const token = userSession.getToken();
        const locale = userSession.getLocale();

        const headers = {
          'x-hasura-role': role,
          'x-hasura-locale': locale,
        };

        if (token) {
          return {
            headers: {
              ...headers,
              authorization: token,
            },
          };
        }

        return { headers };
      },
    })
  );

const composeSplit = ([link, ...links]: {
  test: (op: Operation) => boolean;
  link: ApolloLink;
}[]): ApolloLink => {
  if (links.length === 0) {
    return link.link;
  }

  return split(link.test, link.link, composeSplit(links));
};

const isSubscription = (operation: Operation) => {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === 'OperationDefinition' &&
    (definition as OperationDefinitionNode).operation === 'subscription'
  );
};

const hasRole = (role: string) => (operation: Operation) =>
  isSubscription(operation) &&
  operation.getContext().headers['x-hasura-role'] === role;

export const createLink = (): ApolloLink => {
  const httpLink = new HttpLink({
    uri: `https://${GRAPHQL_API_ENDPOINT.get()}`,
  });

  const links = !ON_SERVER
    ? composeSplit([
        { test: hasRole('superuser'), link: createWsRoleLink('superuser') },
        { test: hasRole('club'), link: createWsRoleLink('club') },
        { test: hasRole('user'), link: createWsRoleLink('user') },
        { test: hasRole('anonymous'), link: createWsRoleLink('anonymous') },
        { test: () => true, link: httpLink },
      ])
    : httpLink;

  return ApolloLink.from([
    new SentryLink(),
    authLink.concat(errorLink).concat(links),
  ]);
};
