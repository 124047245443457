import { CssVarsThemeOptions, extendTheme } from '@mui/material/styles';
import { nonNullable } from '@scorenco/core';
import merge from 'deepmerge';

import { breakpoints } from './breakpoints';
import { lightScheme, blueScheme, greenScheme } from './colorSchemes';
import { componentsOverrides } from './overrides';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { typography } from './typography';

/**
 * Theme de base de Score'n'co.
 */
export const createTheme = (options?: CssVarsThemeOptions) => {
  const theme: CssVarsThemeOptions = {
    cssVarPrefix: 'snc',
    ...lightScheme,
    colorSchemes: {
      light: lightScheme,
      blue: blueScheme,
      green: greenScheme,
    },
    breakpoints,
    shadows,
    typography,
    sizes,
  };

  const extendedTheme = extendTheme(
    // Depuis MUI V6, le lightScheme n'est plus celui activé par défaut, il est
    // activé après green et après blue.
    // merge.all([theme, options].filter(nonNullable))
    merge.all(
      [
        {
          ...theme,
          colorSchemes: {
            light: lightScheme,
          },
        },
        options,
      ].filter(nonNullable)
    )
  );

  extendedTheme.components = componentsOverrides(extendedTheme);

  return extendedTheme;
};
