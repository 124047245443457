import { useMemo } from 'react';

import { css } from '@emotion/react';
import { useTranslation } from '@scorenco/core';

import { Shield, ShieldColorType, ShieldProps } from '../../illustrations';
import { ImageLoader, ImageLoaderProps } from '../../primitives';
import { T_KEYS } from '../../translations';

const sizeAlreadyExists = (logo: string) => /=s\d+x\d+$/.test(logo);
const addSize = (
  width?: number,
  height?: number,
  logo?: string
): string | undefined =>
  width && height && logo && !sizeAlreadyExists(logo)
    ? logo?.includes('scorenco.com/media/club_pictures')
      ? `${logo}=s${width}x${height}`
      : `${logo}?s=${width}x${height}`
    : logo;

export const ClubLogo = ({
  logo,
  name,
  shieldColor,
  width,
  height,
  ...rest
}: ClubLogoProps) => {
  const { t } = useTranslation();
  const Placeholder = useMemo(
    () => (props: Omit<ShieldProps, 'color'>) => (
      <Shield color={shieldColor} {...props} />
    ),
    [shieldColor]
  );

  const imageUrl = useMemo(
    () => addSize(width, height, logo),
    [width, height, logo]
  );

  return (
    <ImageLoader
      src={imageUrl}
      alt={name ? (t(T_KEYS.CLUB_LOGO, { name }) as string) : ''}
      Placeholder={Placeholder}
      css={css`
        object-fit: contain;
        width: 100%;
        height: 100%;
      `}
      {...rest}
    />
  );
};

export type ClubLogoProps = Omit<
  ImageLoaderProps,
  'src' | 'alt' | 'Placeholder'
> & {
  logo?: string;
  name?: string;
  width?: number;
  height?: number;
  shieldColor?: ShieldColorType;
};
