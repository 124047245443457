import { PropsWithChildren, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAmplifyServiceContext } from '../amplify';

import { BREVO_ID } from './env-vars';

/**
 * Brevo est un service de marketing automation et de CRM.
 * Cette intégration ajoute le script Brevo dans le head du document.
 * Cela permet par exemple d'envoyer un mail à un club qui visite la page de
 * facturation pour l'inviter à créer un devis
 */

const insertScript = (email: string) => {
  if (
    typeof window !== 'undefined' &&
    !document.getElementById('sendinblue-js')
  ) {
    const script = document.createElement('script');
    script.id = 'sendinblue-js';
    script.innerHTML = `
      window.sib = {
        equeue: [],
        client_key: '${BREVO_ID.get()}'
      };
      window.sib.email_id = '${email}';
      window.sendinblue = {};
      ['track', 'identify', 'trackLink', 'page'].forEach(function(e) {
        window.sendinblue[e] = function() {
          var t = Array.prototype.slice.call(arguments);
          (window.sib[e] || function() {
            var s = {};
            s[e] = t;
            window.sib.equeue.push(s);
          })(t[0], t[1], t[2], t[3]);
        }
      });
      var n = document.createElement("script"),
          i = document.getElementsByTagName("script")[0];
      n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0,
      n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key,
      i.parentNode.insertBefore(n, i);
      window.sendinblue.page();
    `;
    document.head.appendChild(script);
  }
};

export const BrevoService: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAmplifyServiceContext();
  const router = useRouter();

  useEffect(() => {
    if (user?.info?.email) {
      insertScript(user?.info?.email);
    }
  }, [user]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (
        typeof window !== 'undefined' &&
        window.sendinblue &&
        window.sendinblue.page
      ) {
        window.sendinblue.page();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return <>{children}</>;
};

BrevoService.init = () => {
  if (typeof window !== 'undefined') {
    window.BREVO_ID = BREVO_ID.get();
  }
};
