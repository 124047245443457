import { Stack, StackProps } from '@mui/material';

type ScoreProps = Omit<StackProps, 'children'> & {
  score: number;
  opponentScore: number;
  isWinner?: boolean;
};

export const Score = ({
  score,
  opponentScore,
  isWinner,
  sx,
  ...rest
}: ScoreProps) => {
  if (
    score === null ||
    score === undefined ||
    opponentScore === null ||
    opponentScore === undefined
  ) {
    return null;
  }

  let fontSize = '4rem';

  switch (true) {
    case score < 100 && score >= 10 && opponentScore >= 100:
      fontSize = '3rem';
      break;
    case score >= 100 && opponentScore < 100:
      fontSize = '4rem';
      break;
    case score < 10 && opponentScore >= 10:
      fontSize = '2.5rem';
      break;
    default:
      fontSize = '4rem';
      break;
  }

  let width = '1.2rem';

  if ((score || opponentScore) > 99) {
    width = '1.7rem';
  } else {
    width = '1.2rem';
  }

  return (
    <Stack
      sx={(theme) => ({
        width: width,
        height: '2rem',
        mr: '0.125rem',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        borderRadius: '0.25rem',
        backgroundColor: 'background.paper2',
        color: 'text.primary',
        fontSize: fontSize,
        '&.is-winner': {
          color: 'secondary.main',
        },
        ...sx,
      })}
      className={[isWinner && 'is-winner'].join(' ')}
      {...rest}
    >
      <svg
        display="block"
        width="100%"
        height="100%"
        viewBox={`0 0 ${41 * String(score).length + 20} 100`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <text
          style={{ fontSize: 'inherit' }}
          fill="currentColor"
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
        >
          {score}
        </text>
      </svg>
    </Stack>
  );
};
