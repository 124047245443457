import { PropsWithChildren } from 'react';

import { AmplifyService, BrevoService } from '@scorenco/core';

import { GetLayout } from '../../types';

import { NextAmplifyServiceOptions } from './types';
import { useAuthRedirection } from './useAuthRedirection';

const AuthentificationComponent = ({
  children,
  ...rest
}: PropsWithChildren<NextAmplifyServiceOptions>) => {
  const { isReady } = useAuthRedirection(rest);

  if (!isReady) {
    return null;
  }

  return <>{children}</>;
};

export const getLayoutFn =
  (layoutProps: NextAmplifyServiceOptions = {}): GetLayout =>
  (page) => (
    <AmplifyService>
      <BrevoService>
        <AuthentificationComponent {...layoutProps}>
          {page}
        </AuthentificationComponent>
      </BrevoService>
    </AmplifyService>
  );
