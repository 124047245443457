import type * as Sentry from '@sentry/nextjs';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

import { env } from './env';

type SentryConfig = Parameters<(typeof Sentry)['init']>[0];

export const defaultConfig: SentryConfig = env.NEXT_PUBLIC_SENTRY_ENABLED
  ? {
      enabled: env.NEXT_PUBLIC_SENTRY_ENABLED,
      dsn: env.NEXT_PUBLIC_SENTRY_DSN,
      environment: env.NEXT_PUBLIC_SST_STAGE,
      debug: false,
      tracesSampleRate: 0.05,
      beforeBreadcrumb: excludeGraphQLFetch,
      allowUrls: [/localhost/, /scorenco\.com/],
      release: env.NEXT_PUBLIC_VERSION,
      denyUrls: [
        // Chrome extensions
        /^chrome:\/\//i,
        /chrome-extension:/i,
        /extensions\//i,

        // Locally saved copies
        /file:\/\//i,

        // Safari extensions.
        /safari-web-extension:/i,
        /safari-extension:/i,

        // Erreurs lié à Flashtalking (ads server)
        /pageFold/,
        /ftUtils/,
      ],
      ignoreErrors: [
        // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
        /^Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+$/,
        // https://stackoverflow.com/a/50387233/14809536
        /^ResizeObserver loop limit exceeded$/,
      ],
    }
  : { enabled: env.NEXT_PUBLIC_SENTRY_ENABLED };
